import React from 'react';
import { FaBars } from "react-icons/fa";
import './AboutMe.css';
import { Link } from 'react-router-dom';
import { LiaDownloadSolid } from "react-icons/lia";
import { Dropdown } from 'react-bootstrap';
import Header from '../../components/Header';

// import { useMediaQuery } from 'react-responsive';

const AboutMe = () => {
    // const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    // const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1224px)' });
    // const isMobile = useMediaQuery({ query: '(max-width: 768px)' });



    return (
        <>
            <div style={{ backgroundColor: '#ebe8d7', minHeight: '100vh', overflow: 'hidden' }}>
                {/* HEADER */}
                <div className='header d-flex align-items-center'>
                    <div className='header-item d-flex w-100  align-items-center justify-content-between px-0' >
                        <div className="d-flex align-items-center">
                            <Dropdown className="custom-dropdown">
                                <Dropdown.Toggle id="dropdown-custom">
                                    <FaBars />
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ backgroundColor: 'black' }}>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/about-me">about Me+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/book">book+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/award">awards+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/contact">contact+</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Link to="/" >
                                <img class="logo" src='./assets/logo.png' />
                                {/* <h1 className='logo-text'>the<strong>shuj</strong></h1> */}
                            </Link>
                        </div>


                        <div className="text-end ">
                            <div className="Hlinks ">
                                <Link to="/about-me">about me<strong>+</strong></Link>
                                <Link to="/book">book<strong>+</strong></Link>
                                <Link to="/award">awards<strong>+</strong></Link>
                                <Link to="/contact">contact<strong>+</strong></Link>
                            </div>

                            {/* <img className='signature-img' src='./ST Jpgs/logos.png' alt='signature' /> */}
                        </div>


                    </div>
                </div>
                {/* HEADER END */}
                <div className="Acontainer padding-top">
                    <div className="Amain">
                        <div className="Acontent">
                            <div className='about-text '>
                                <div className="about-text-wrap  d-inline-block position-relative">

                                    <h1>about me</h1>
                                    <div className="Alogos d-none d-lg-block">
                                        <img src="./ST Jpgs/logos.png" alt="logo1" />
                                    </div>
                                    <div className="Arobot">
                                        <img src="./characters/AboutMe.png" alt="darth vader" />
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="Alinks">
                            <div className='right-text'>
                                <p>
                                    With over 20 years of experience in art direction and creative direction
                                    with renowned brands in the Aviation, FMCG (Fast-Moving Consumer Goods),
                                    Medical, FNB (Food and Beverage), Automotive, and Property sectors, my
                                    expertise lies in the development of highly creative and concept-driven
                                    experiences. I excel at crafting innovative solutions that resonate
                                    across all forms of media, ensuring that each campaign is impactful and
                                    engaging.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className='footer-about' style={{ backgroundColor: '#ebe8d7', width: "100%" }}>
                    <div className="footdivs-about" >
                        <div className="d-flex flex-wrap flex-grow-1 ft-about-text w-sm-75">
                            <p className='c text-1'>

                                Apart from advertising, my creative interests extend to photography,
                                music, filmmaking, and engaging in immersive role-playing games such as
                                Call of Duty and World of Warcraft.<br/>
                                US B1/B2 Visa for US Traveling
                            </p>
                        </div>
                        <p style={{ alignSelf: "center", marginLeft: 'auto' }} className="fd3 resume">
                            <a href="./ST Jpgs/Syed%20Shuja%20Resume%20Art%202024.pdf" download target="_blank" style={{ textDecoration: 'none', color: 'black' }}>
                                <LiaDownloadSolid /> <strong style={{ fontFamily: 'Regular-text' }}>Download Resume</strong>
                            </a>
                        </p>
                    </div>
                </footer>
            </div>

        </>
    );
}

export default AboutMe;
