import './AwardPage.css';
import React, { useState } from 'react';
import { FaBars } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Dropdown } from 'react-bootstrap';
import Header from "../../components/Header";

export default function AwardPage() {
    const [showModal, setShowModal] = useState(false);
    const isTabletOrMobile = useMediaQuery({ minWidth: 1000 });

    const handleModalClose = () => setShowModal(false);
    const [modalImageIndex, setModalImageIndex] = useState(0);
    const totalImages = 6;

    const handleModalShow = (index) => {
        setModalImageIndex(index);
        setShowModal(true);
    };
    const handlePrevImage = () => {
        if (modalImageIndex > 0) {
            setModalImageIndex(modalImageIndex - 1);
        }
        else {
            setModalImageIndex(totalImages - 1);
        }
    };

    const handleNextImage = () => {
        if (modalImageIndex < totalImages - 1) {
            setModalImageIndex(modalImageIndex + 1);
        }
        else {
            setModalImageIndex(0);

        }
    };
    return (
        <>
            <div className="award-start" style={{ backgroundColor: '#ebe8d7', minHeight: isTabletOrMobile ? '100vh' : 'auto' }}>
                {/* HEADER */}
                <div className='header d-flex align-items-center'>
                    <div className='header-item d-flex w-100  align-items-center justify-content-between px-0' >
                        <div className="d-flex align-items-center">
                            <Dropdown className="custom-dropdown">
                                <Dropdown.Toggle id="dropdown-custom">
                                    <FaBars />
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ backgroundColor: 'black' }}>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/about-me">about Me+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/book">book+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/award">awards+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/contact">contact+</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Link to="/" >
                                <img class="logo" src='./assets/logo.png' />
                                {/* <h1 className='logo-text'>the<strong>shuj</strong></h1> */}
                            </Link>
                        </div>


                        <div className="text-end ">
                            <div className="Hlinks ">
                                <Link to="/about-me">about me<strong>+</strong></Link>
                                <Link to="/book">book<strong>+</strong></Link>
                                <Link to="/award">awards<strong>+</strong></Link>
                                <Link to="/contact">contact<strong>+</strong></Link>
                            </div>

                            {/* <img className='signature-img' src='./ST Jpgs/logos.png' alt='signature' /> */}
                        </div>


                    </div>
                </div>
                {/* HEADER END */}
                <div className='award-main-content padding-top' > {/*style={{ backgroundColor: '#ebe8d7' }}*/}
                    <div className="award-page" >
                        <div className="main-column-award">
                            <h1 className="main-heading">
                                awards
                                <div className="award-img">
                                    <img src="./characters/Awards.png" className="avatar-award" />
                                </div>
                            </h1>

                        </div>
                        <div className="grid-container">
                            {Array.from({ length: totalImages }).map((_, index) => (
                                <div key={index} className="grid-item">
                                    <img src={`./AwardsPopus/a${index + 1}.jpg`} alt={`Award ${index + 1}`} className="award-image" />
                                    <span className="label">click here to expand</span>
                                    <button className="add-button" onClick={() => handleModalShow(index)}>+</button>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>

                <div className='hello-footer footer-mob' >
                    <div className='hello-footer footer-mob' >

                        <div className='ft-copy flex-wrap d-flex align-items-center justify-content-between   '>
                            <div id='copyright' className='ms-auto'>
                                All reserved to <b>theshuj</b> copyright 2024
                            </div>
                            {/* <div className='text-start'>Digital Art Directed &amp; Designed by <Link to={"/"}>TheShuj</Link>. <br />
                                Developed, Produced and Maintained by <a href="https://webstings.com/" target='_blank'>WebStings</a></div>
                            <div className='text-end'>All rights reserved to theshuj, copyright 2024</div> */}

                        </div>

                    </div>


                </div>


                {/* <footer className='footer-about' style={{ overflow: 'hidden', backgroundColor: '#ebe8d7', width: "100%" }}>
                    <div className="footdivs-about " >
                        <p className='text-1'>

                            Apart from advertising, my creative interests extend to photography,
                            music, filmmaking, and engaging in immersive role-playing games such as
                            Call of Duty and World of Warcraft.
                        </p>
                        <p className='text-2' >
                            Holding UAE Residence Visa, And
                            <span >
                                US B1/B2 Visa for US Traveling
                            </span>
                        </p>
                        {/* <p style={{ alignSelf: "center", marginLeft: 'auto', paddingRight: '60px' }} className="fd3">
                        <a href="/ST Jpgs/Syed%20Shuja%20Resume%20Art%202024.pdf" download target="_blank" style={{ textDecoration: 'none', color: 'black' }}>
                            <LiaDownloadSolid /> <strong style={{fontFamily: 'Regular-text'}}>Download Resume</strong>
                        </a>
                    </p> */}
                {/* <div className="ft-img me-0 d-none d-xxl-block">
                            <img className='w-100' src='./ST Jpgs/logos.png' alt='signature' />
                        </div>
                    </div>
                </footer> */}


                <Modal className='film-modal aw award-modal' show={showModal} onHide={handleModalClose} dialogClassName="gray-modal"
                    aria-labelledby="custom-modal-styling-title" centered >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='Awardcontainer'>
                            <Modal.Header >
                                <div className="row w-100">
                                    <div className="col-5">
                                        <Modal.Title className="modal-title" id="custom-modal-styling-title"><h2 className='ps-0' style={{ fontFamily: 'ExtraBold' }}>awards</h2> </Modal.Title>
                                        <Button variant="light" className="close-button-modal" onClick={handleModalClose} >
                                            Close
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Header>

                            <Modal.Body className="modal-body">
                                <img
                                    src={`./AwardsPopus/p${modalImageIndex + 1}.jpg`}
                                    alt={`Award ${modalImageIndex + 1}`}
                                    className="modal-image" />

                            </Modal.Body>
                            <Modal.Footer>
                                <div className="Awardfooter">
                                    <div className="Awardmaindiv align-items-center">
                                        <div className='agency my-0 d-none d-lg-block'>
                                            <span style={{ color: 'red', fontFamily: 'ExtraBold' }}>Agency</span>
                                            <p style={{ color: 'white', fontFamily: 'ExtraBold' }}>FP7 McCANN-Dubai</p>
                                        </div>

                                        <div className='buttons justify-content-center justify-content-lg-end'>
                                            {modalImageIndex > 0 &&
                                                <div>
                                                    <img src="./assets/Arrows-prev.png" alt="left-arrow" onClick={handlePrevImage} />
                                                </div>
                                            }
                                            {modalImageIndex < 5 &&
                                                <div className='ms-lg-auto'>
                                                    <img src="./assets/Arrows-next.png" alt="right-arrow" onClick={handleNextImage} />

                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>

                            </Modal.Footer>
                        </div>
                    </div>
                </Modal>

            </div>



        </>
    );
}
