import React from 'react'
import './Book.css';
import { FaBars } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Header from '../../components/Header';


export default function Book() {
    return (
        <>
            <div className='bookStart' style={{ backgroundColor: '#ebe8d7', overflow: 'hidden' }}>
                {/* HEADER */}
                <div className='header d-flex align-items-center'>
                    <div className='header-item d-flex w-100  align-items-center justify-content-between px-0' >
                        <div className="d-flex align-items-center">
                            <Dropdown className="custom-dropdown">
                                <Dropdown.Toggle id="dropdown-custom">
                                    <FaBars />
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ backgroundColor: 'black' }}>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/about-me">about Me+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/book">book+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/award">awards+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/contact">contact+</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Link to="/" >
                                <img class="logo" src='./assets/logo.png' />
                                {/* <h1 className='logo-text'>the<strong>shuj</strong></h1> */}
                            </Link>
                        </div>


                        <div className="text-end ">
                            <div className="Hlinks ">
                                <Link to="/about-me">about me<strong>+</strong></Link>
                                <Link to="/book">book<strong>+</strong></Link>
                                <Link to="/award">awards<strong>+</strong></Link>
                                <Link to="/contact">contact<strong>+</strong></Link>
                            </div>

                            {/* <img className='signature-img' src='./ST Jpgs/logos.png' alt='signature' /> */}
                        </div>


                    </div>
                </div>
                {/* HEADER END */}

                <div className="Bookcontainer padding-top">
                    <div className="Bmain">
                        <div className="Bcontent">
                            <h1>
                                book
                                <div className='b-img '>
                                    <img src="./characters/Book.png" alt="stormtrooper" />
                                </div>
                            </h1>
                            {/* <div className='b-img d-none d-lg-block'>
                                <img src="./characters/book.png" alt="stormtrooper" />
                            </div> */}
                        </div>
                        <div className="Blinks">
                            <Link to="/film">films<strong>+</strong></Link>
                            <Link to="/compaign">campaign<strong>+</strong></Link>
                            <Link to="/digital-and-design">digitall<strong>+</strong></Link>
                            <Link to="/digital-and-design">design<strong>+</strong></Link>
                        </div>
                    </div>
                </div>
            </div>
            <footer className='book-footer'>

                <div className='hello-footer footer-mob' >

                    <div className='ft-copy flex-wrap d-flex align-items-center justify-content-between   '>
                        <div id='copyright' className='ms-auto'>
                            All reserved to <b>theshuj</b> copyright 2024
                        </div>
                        {/* <div className='text-start'>Digital Art Directed &amp; Designed by <Link to={"/"}>TheShuj</Link>. <br />
                                Developed, Produced and Maintained by <a href="https://webstings.com/" target='_blank'>WebStings</a></div>
                            <div className='text-end'>All rights reserved to theshuj, copyright 2024</div> */}

                    </div>

                </div>

            </footer>

        </>
    )
}
