import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContactPage from './Pages/Contact/ContactPage';
import AwardPage from './Pages/Award/AwardPage';
import FilmsPage from './Pages/Films/FilmsPage';
import CompaignsPage from './Pages/Campaigns/CompaignsPage';
import DesignDigitalPage from './Pages/DesignDigital/DesignDigitalPage';
import Hello from './Pages/Hello/Hello';
import AboutMe from './Pages/AboutMe/AboutMe';
import Book from './Pages/Book/Book';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>

          <Route path="/" element={<Hello />} />
          <Route path="/about-me" element={<AboutMe />} />
          <Route path="/book" element={<Book />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/award" element={<AwardPage />} />
          <Route path="/film" element={<FilmsPage />} />
          <Route path="/compaign" element={<CompaignsPage />} />
          <Route path="/digital-and-design" element={<DesignDigitalPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
