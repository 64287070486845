import React from 'react';
import './ContactPage.css';
import { FaBars } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { IoMailSharp } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Dropdown } from 'react-bootstrap';
import Header from "../../components/Header";



export default function ContactPage() {
    const isTabletOrMobile = useMediaQuery({ minWidth: 1000 });

    return (
        <>
            <div className='contact-page '>
                {/* HEADER */}
                <div className='header d-flex align-items-center'>
                    <div className='header-item d-flex w-100  align-items-center justify-content-between px-0' >
                        <div className="d-flex align-items-center">
                            <Dropdown className="custom-dropdown">
                                <Dropdown.Toggle id="dropdown-custom">
                                    <FaBars />
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ backgroundColor: 'black' }}>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/about-me">about Me+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/book">book+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/award">awards+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/contact">contact+</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Link to="/" >
                                <img class="logo" src='./assets/logo.png' />
                                {/* <h1 className='logo-text'>the<strong>shuj</strong></h1> */}
                            </Link>
                        </div>


                        <div className="text-end ">
                            <div className="Hlinks ">
                                <Link to="/about-me">about me<strong>+</strong></Link>
                                <Link to="/book">book<strong>+</strong></Link>
                                <Link to="/award">awards<strong>+</strong></Link>
                                <Link to="/contact">contact<strong>+</strong></Link>
                            </div>

                            {/* <img className='signature-img' src='./ST Jpgs/logos.png' alt='signature' /> */}
                        </div>


                    </div>
                </div>
                {/* HEADER END */}
                <main className='main-content padding-top'>
                    <div className='main '>
                        <div className='content flex-wrap d-flex w-100  h-100'>
                            <div className='main-column '>
                                <h1 className='main-heading'>contact
                                    <div className="contact-img">
                                        <img src='./characters/Contact.png' alt='contact Avatar' className='avatar' />
                                    </div>
                                </h1>
                            </div>
                            <div className=' ms-auto info-box' >
                                {/* <div>
                                    <div className='info-row'>
                                        <IoLocationSharp className='icon icon-1' />
                                        <h4>currently located in dubai, uae.</h4>
                                    </div>
                                </div> */}
                                <div >
                                    <div className='info-row'>
                                        <a href="mailto:shuja.syedhussain@gmail.com">
                                            <IoMailSharp className='icon' />
                                            <span >shuja.syedhussain@gmail.com</span>
                                        </a>
                                    </div>
                                </div>
                                <div >
                                    <div className='info-row last'>
                                        <IoLogoWhatsapp className='icon' />
                                        <span >+971 52 786 7766</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <div className='hello-footer footer-mob' >
                    <div className='hello-footer footer-mob' >
                        <div className='ft-copy flex-wrap d-flex align-items-center justify-content-between   '>
                            <div id='copyright' className='ms-auto'>
                                All reserved to <b>theshuj</b> copyright 2024
                            </div>
                            {/* <div className='text-start'>Digital Art Directed &amp; Designed by <Link to={"/"}>TheShuj</Link>. <br />
                                Developed, Produced and Maintained by <a href="https://webstings.com/" target='_blank'>WebStings</a></div>
                            <div className='text-end'>All rights reserved to theshuj, copyright 2024</div> */}

                        </div>
                        

                    </div>

                </div>

            </div>

        </>
    );
}
