import "./CompaignsPage.css";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Dropdown } from "react-bootstrap";
import ReactPlayer from 'react-player';


export default function CompaignsPage() {
  const isTabletOrMobile = useMediaQuery({ minWidth: 992 });

  const [showModal, setShowModal] = useState(false);
  const [showVideo, setShowVideo] = useState(false);



  // VIDEOS
  const handleModalClose = () => {
    setShowModal(false);
    setShowVideo(false);
  }
  const handleImageClick = () => {
    setShowVideo(true);
  };
  // VIDEOS END


  // const handleModalClose = () => setShowModal(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);
  const totalImages = 6;

  const handleModalShow = (index) => {
    setModalImageIndex(index);
    setShowModal(true);
  };
  const handlePrevImage = () => {
    if (modalImageIndex > 0) {
      setModalImageIndex(modalImageIndex - 1);
    } else {
      setModalImageIndex(totalImages - 1);
    }
  };

  const handleNextImage = () => {
    if (modalImageIndex < totalImages - 1) {
      setModalImageIndex(modalImageIndex + 1);
    } else {
      setModalImageIndex(0);
    }
  };
  return (
    <>
      <div
        className="start-compaign"

      >


        {/* HEADER */}
        <div className='header d-flex align-items-center'>
          <div className='header-item flex-wrap d-flex w-100  align-items-center justify-content-between ' >
            <div className="d-flex flex-column flex-sm-row flex-wrap align-sm-items-center">
              <div className="d-flex align-items-center">
                <Dropdown className="custom-dropdown">
                  <Dropdown.Toggle id="dropdown-custom">
                    <FaBars />
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ backgroundColor: 'black' }}>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/about-me">about Me+</Dropdown.Item>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/book">book+</Dropdown.Item>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/award">awards+</Dropdown.Item>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/contact">contact+</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Link to="/" >
                  <img class="logo" src='./assets/logo.png' />
                  {/* <h1 className='logo-text'>the<strong>shuj</strong></h1> */}
                </Link>
              </div>

              <div className="text-end   ">

                <div
                  className="navlinks"
                  style={{
                    marginLeft: !isTabletOrMobile && "2.5rem",
                    fontSize: !isTabletOrMobile && "10px",
                  }}
                >
                  <Link to="/film" >film<strong>+</strong>
                  </Link>
                  <Link to="/compaign" className="active">campaign<strong>+</strong></Link>
                  <Link to="/digital-and-design">digitall &amp; design<strong>+</strong></Link>

                  <img className="arrow" src="./ST Jpgs/arrow.png" alt="" />
                </div>
                {/* <img className='signature-img' src='./ST Jpgs/logos.png' alt='signature' /> */}
              </div>

            </div>



            <div className="text-end ">
              {/* <div className="Hlinks ">
                        <Link to="/about-me">about me<strong>+</strong></Link>
                        <Link to="/book">book<strong>+</strong></Link>
                        <Link to="/award">awards<strong>+</strong></Link>
                        <Link to="/contact">contact<strong>+</strong></Link>
                    </div> */}
              <div className="Hlinks film-m-menu">
                <Link to="/about-me">about me<strong>+</strong></Link>
                <Link to="/book">book<strong>+</strong></Link>
                <Link to="/award">awards<strong>+</strong></Link>
                <Link to="/contact">contact<strong>+</strong></Link>
              </div>


            </div>


          </div>
        </div>
        {/* HEADER END */}

        <div className="c-main" >
          <div className="Compaigns-page">
            <div class="text-side">
              <div class="content-Compaigns  mb-lg-0" style={{ fontFamily: 'ExtraBold' }}>
                <h6>
                  Some of my art direction work, which showcases the ideas and
                  thought processes behind each brand's campaigns.
                </h6>
              </div>
              <div class="Compaigns-section ">
                <div class="Compaigns-description " style={{ fontFamily: 'ExtraBold' }}>
                  <h4>
                    Art <br /> direction <br /> & design <br /> are central <br /> to my role <br /> in
                    advertision.
                  </h4>
                </div>
                <div class="Compaigns-image">
                  <img src="./characters/Campaigns.png" alt="campaigns Avatar" />
                </div>
              </div>
            </div>
            <div
              className="grid-container-Compaigns"

            >
              {Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={index}
                  className="grid-item-Compaigns"

                >
                  <img
                    src={`./CompaignsPopups/c${index + 1}.jpg`}
                    alt={`Compaigns ${index + 1}`}
                    className="award-image"
                  />
                  <span className="label">click here to expand</span>
                  <button
                    className="add-button"
                    onClick={() => handleModalShow(index)}
                  >
                    +
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="footer footer-film"
        >
          <div className="ft-film-text">
            <div
              className="filmfotdivs"
            >
              <div className="text-1">

                After graduating in filmmaking, I explored the artistic side of advertising, guided by my mentor Sachin Chitale at TBWA/RAAD. He helped me develop my skills in art direction, compositions, and illustrations. This started my career in Art
              </div>
              <div className="text-2">
                illustrations. This started my career in Art Direction. I later worked with renowned art titans Ryan Akitson and Kapil Bhimkar, significantly enhancing my skills in art direction and design.
              </div>
            </div>
          </div>


        </div>

      </div>

      <Modal
        className="film-modal cm campaign-modal"
        show={showModal}
        onHide={handleModalClose}
        dialogClassName="gray-modal"
        aria-labelledby="custom-modal-styling-title"
        centered
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="Awardcontainer">
            <Modal.Header>
              <div className="row w-100">
                <div className="col-4 align-self-center">
                  <Modal.Title className="modal-title " id="custom-modal-styling-title">
                    <h2 className="text-nowrap" style={{ marginLeft: '20px', fontFamily: 'ExtraBold', fontSize: isTabletOrMobile ? 'xxx-large' : 'xx-large' }}>campaigns</h2>{" "}
                  </Modal.Title>
                </div>
                <div className="col d-none d-lg-block">



                  {modalImageIndex === 0 && (
                    <div
                      className="agency"
                    >
                      <div className="d-flex">
                        <div
                          className="top-header-text pe-3"
                          style={{ borderRight: "1px solid white" }}
                        >
                          <span style={{ color: "red" }}>Agency</span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Leo Burnett-Dubai
                            </small>
                          </p>
                        </div>
                        <div
                          className="top-header-text px-3"
                          style={{ borderRight: "1px solid white" }}
                        >
                          <span style={{ color: "red" }}>Production House</span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Prodigiious-Dubai
                            </small>
                          </p>
                        </div>

                        <div className="top-header-text ps-3 ">
                          <span style={{ color: "red" }}>Photography by</span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Niquita Bento - www.niquibento.com
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalImageIndex === 1 && (
                    <div
                      className="agency"
                    >
                      <div className="d-flex">
                        <div className="top-header-text pe-3">
                          <span style={{ color: "red" }}>Agency</span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Havas Worldwide-Dubai
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalImageIndex === 2 && (
                    <div
                      className="agency"
                    >
                      <div className="d-flex">
                        <div className="top-header-text">
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Agency
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Leo Burnett-Dubai
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalImageIndex === 3 && (
                    <div
                      className="agency"
                    >
                      <div className="d-flex">
                        <div
                          className="top-header-text pe-3"
                          style={{ borderRight: "1px solid white" }}
                        >
                          <span style={{ color: "red" }}>Agency</span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Leo Burnett-Dubai
                            </small>
                          </p>
                        </div>
                        <div className="top-header-text px-3">
                          <span style={{ color: "red" }}>Production House</span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Zombie - Brazil
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalImageIndex === 4 && (
                    <div
                      className="agency"
                    >
                      <div className="d-flex">
                        <div className="top-header-text pe-3">
                          <span style={{ color: "red" }}>Agency</span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Havas Worldwide-Dubai
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalImageIndex === 5 && (
                    <div
                      className="agency"
                    >
                      <div className="d-flex">
                        <div className="top-header-text">
                          <span style={{ color: "red" }}>Agency</span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              FP7 McCANN-Dubai
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <Button
                    variant="light"
                    className="close-button-modal"
                    onClick={handleModalClose}
                  >
                    Close
                  </Button>
                </div>
                <div className="col d-block d-lg-none">
                  <Button
                    variant="light"
                    className="close-button-modal"
                    onClick={handleModalClose}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body className="modal-body">

              {
                (modalImageIndex + 1) == 5 ? <div className="col-lg-12 video-section">
                  {showVideo ? (
                    <ReactPlayer
                      url={`./CompaignsPopups/videos/f${modalImageIndex + 1}.mp4`}
                      className='modal-video'
                      controls
                      width='100%'
                      height='100%'
                      playing
                      onEnded={() => setShowVideo(false)}
                    />
                  ) : (
                    <img
                      src={`./CompaignsPopups/Desktop/c${modalImageIndex + 1}.jpg`}
                      alt={`Desktop Films ${modalImageIndex + 1}`}
                      className="modal-image"
                      onClick={handleImageClick}
                    />
                  )}
                </div> :
                  <img

                    onClick={handleImageClick}
                    src={
                      isTabletOrMobile
                        ? `./CompaignsPopups/Desktop/c${modalImageIndex + 1}.jpg`
                        : `./CompaignsPopups/Mobile/c${modalImageIndex + 1}.jpg`
                    }
                    alt={`Compaigns ${modalImageIndex + 1}`}
                    className="modal-image"
                  />
              }






            </Modal.Body>
            <Modal.Footer>
              <div className="Awardfooter ">
                <div className="Awardmaindiv  ">
                  <div className="w-100 d-none d-lg-block  ">
                    {modalImageIndex === 0 && (
                      <>
                        <div className="row w-100">
                          <div className="col-4 ft-wrap">
                            <div className="agency my-0 w-100" >
                              <div className="row w-100">
                                <div className="col-xl-4 col-lg-6">
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "10px",
                                      lineHeight: "1em",

                                    }}
                                  >
                                    {" "}
                                    Shared some visual style references with the
                                    photographer to get the result was amazing working
                                    with Niquita.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-7">
                            <div
                              className="agency my-0"
                            >
                              <span style={{ color: "red", fontWeight: "bold" }}>
                                Team
                              </span>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div className="row">
                                  <div className="col-12">
                                    <div className="row">
                                      <div
                                        className="col-4"
                                        style={{ borderRight: "1px solid white" }}
                                      >
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "x-small",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Creative Director
                                        </div>
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "10px",
                                            lineHeight: "1em",
                                          }}
                                        >
                                          {" "}
                                          Diviyan Kripilani
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "x-small",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Creative Director
                                        </div>
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "10px",
                                            lineHeight: "1em",
                                          }}
                                        >
                                          {" "}
                                          Kapil Bhimekar
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "10px",
                                    lineHeight: "1em",
                                  }}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Associate Creative Director (Copy)
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Farhan Saddique
                                  </div>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Senior Art Director
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Syed Shuja
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </>
                    )}
                    {modalImageIndex === 1 && (
                      <>

                        <div className="row w-100">
                          <div className="col-4 ft-wrap"></div>

                          <div className="col-7">

                            <div
                              className="agency my-0"

                            >
                              <span style={{ color: "red", fontWeight: "bold" }}>
                                Team
                              </span>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "10px",
                                    lineHeight: "1em",
                                  }}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Creative Director
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Samir Lahoud
                                  </div>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Senior Art Director / Design
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Syed Shuja
                                  </div>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Senior Copywriter
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Britney Sinde
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>


                      </>
                    )}
                    {modalImageIndex === 2 && (
                      <>
                        <div className="row w-100">
                          <div className="col-4 ft-wrap"></div>
                          <div className="col-7">
                            <div
                              className="agency my-0"

                            >
                              <span style={{ color: "red", fontWeight: "bold" }}>
                                Team
                              </span>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "10px",
                                    lineHeight: "1em",
                                  }}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Creative Director
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Diviyan Kripilani
                                  </div>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Associate Creative Director (Copy)
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Farhan Saddique
                                  </div>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Senior Art Director
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Syed Shuja
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                      </>
                    )}
                    {modalImageIndex === 3 && (
                      <>
                        <div className="row w-100">
                          <div className="col-4 ft-wrap">
                            <div className="row">
                              <div className="col-6">
                                <div
                                  className="agency  my-0"
                                >
                                  <div
                                    className="row"
                                    style={{
                                      color: "white",
                                      fontSize: "10px",
                                      lineHeight: "1em",
                                    }}
                                  >
                                    {" "}
                                    <p className="col-12 mb-2">
                                      This is animated story of a couple in pursuit of
                                      happiness and security for their family in the form
                                      of a healthy bank balance.
                                    </p>

                                  </div>
                                  <div
                                    className="row"
                                    style={{
                                      color: "white",
                                      fontSize: "10px",
                                      lineHeight: "1em",
                                    }}
                                  >
                                    <div className="col-12">
                                      But phase in their life comes with its own needs and
                                      challenges.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div
                                  className="row"
                                  style={{
                                    color: "white",
                                    fontSize: "10px",
                                    lineHeight: "1em",
                                  }}
                                >
                                  <div className="col-12">
                                    And so, In the pursuit of the perfect life balance,
                                    the couple learns and adapts to any new chapter of
                                    their life. And their financial knowladge evolves
                                    with them.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-7">
                            <div
                              className="agency my-0"
                            // style={{
                            //   width: "30%",
                            //   marginTop: "0.5rem",
                            //   marginLeft: "7rem",
                            // }}
                            >
                              <span style={{ color: "red", fontWeight: "bold" }}>
                                Team
                              </span>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "10px",
                                    lineHeight: "1em",
                                  }}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Creative Director
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Diviyan Kripilani
                                  </div>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Associate Creative Director (Copy)
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Farhan Saddique
                                  </div>
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Senior Art Director
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Syed Shuja
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                      </>
                    )}
                    {modalImageIndex === 4 && (
                      <>
                        <div className="row w-100">
                          <div className="col-4 ft-wrap">
                            <div className="agency my-0">
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "20px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div>Countless</div>
                                <div>Expression</div>
                                <div>on Social Media</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-7">
                            <div
                              className="agency my-0"
                            >
                              <span style={{ color: "red", fontWeight: "bold" }}>
                                Team
                              </span>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "10px",
                                    lineHeight: "1em",
                                  }}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Creative Director{" "}
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Samir Lahoud
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="row">
                                      <div
                                        className="col-4"
                                        style={{ borderRight: "1px solid white" }}
                                      >
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "x-small",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Senior Art Director
                                        </div>
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "10px",
                                            lineHeight: "1em",
                                          }}
                                        >
                                          {" "}
                                          Syed Shuja
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "x-small",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Senior Art Director
                                        </div>
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "10px",
                                            lineHeight: "1em",
                                          }}
                                        >
                                          {" "}
                                          Shehryaar Mukhtaar
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "10px",
                                    lineHeight: "1em",
                                  }}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "x-small",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Senior Copywriter
                                  </div>
                                  <div
                                    style={{ color: "white", fontSize: "x-small" }}
                                  >
                                    Britney Sinde
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                      </>
                    )}
                    {modalImageIndex === 5 && (
                      <>
                        <div className="row w-100">
                          <div className="col-4 ft-wrap">
                            <div className="agency my-0">
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                                  Launch
                                </div>
                                <div
                                  className="row"
                                  style={{
                                    color: "white",
                                    fontSize: "10px",
                                    lineHeight: "1em",
                                  }}
                                >
                                  {" "}
                                  <div className="col-12">
                                    Our QR codes will be present all around the Middle
                                    east region, pin-pointing at historical location
                                    that we covered.
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{
                                    color: "white",
                                    fontSize: "10px",
                                    lineHeight: "1em",
                                    marginTop: "7px",
                                  }}
                                >
                                  {" "}
                                  <div className="col-12">
                                    We will invite people to find these landmarks
                                    through posts and stories on our social media
                                    platfarms.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-7">
                            <div
                              className="agency my-0"

                            >
                              <span style={{ color: "red", fontWeight: "bold" }}>
                                Team
                              </span>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Creative Director
                                </div>
                                <div style={{ color: "white", fontSize: "x-small" }}>
                                  Aunindo Sen
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Senior Art Director
                                </div>
                                <div style={{ color: "white", fontSize: "x-small" }}>
                                  Syed Shuja
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                      </>
                    )}
                  </div>
                  <div className="buttons justify-content-center justify-content-lg-end">
                    {modalImageIndex > 0 && (
                      <div >
                        <img
                          src="./assets/Arrows-prev.png"
                          alt="left-arrow"
                          onClick={handlePrevImage}
                        />
                      </div>
                    )}{" "}
                    {modalImageIndex < 5 && (
                      <div className="ms-lg-auto">
                        <img
                          src="./assets/Arrows-next.png"
                          alt="right-arrow"
                          onClick={handleNextImage}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
}
