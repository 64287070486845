import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./DesignDigitalPage.css";
import { Modal, Button } from "react-bootstrap";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Dropdown } from "react-bootstrap";

export default function DesignDigitalPage() {
  const isTabletOrMobile = useMediaQuery({ minWidth: 1000 });

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);
  const totalImages = 3;

  const handleModalShow = (index) => {
    setModalImageIndex(index);
    setShowModal(true);
  };
  const handlePrevImage = () => {
    if (modalImageIndex > 0) {
      setModalImageIndex(modalImageIndex - 1);
    } else {
      setModalImageIndex(totalImages - 1);
    }
  };

  const handleNextImage = () => {
    if (modalImageIndex < totalImages - 1) {
      setModalImageIndex(modalImageIndex + 1);
    } else {
      setModalImageIndex(0);
    }
  };

  return (
    <>
      <div
        className="start-Digital"

      >
        {/* HEADER */}
        <div className='header d-flex align-items-center'>
          <div className='header-item flex-wrap d-flex w-100  align-items-center justify-content-between ' >
            <div className="d-flex flex-column flex-sm-row flex-wrap align-sm-items-center">
              <div className="d-flex align-items-center">
                <Dropdown className="custom-dropdown">
                  <Dropdown.Toggle id="dropdown-custom">
                    <FaBars />
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ backgroundColor: 'black' }}>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/about-me">about Me+</Dropdown.Item>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/book">book+</Dropdown.Item>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/award">awards+</Dropdown.Item>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/contact">contact+</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Link to="/" >
                  <img class="logo" src='./assets/logo.png' />
                  {/* <h1 className='logo-text'>the<strong>shuj</strong></h1> */}
                </Link>
              </div>

              <div className="text-end   ">

                <div
                  className="navlinks"
                  style={{
                    marginLeft: !isTabletOrMobile && "2.5rem",
                    fontSize: !isTabletOrMobile && "10px",
                  }}
                >
                  <Link to="/film">film<strong>+</strong>
                  </Link>
                  <Link to="/compaign">campaign<strong>+</strong></Link>
                  <Link to="/digital-and-design" className="active">digitall &amp; design<strong>+</strong></Link>

                  <img className="arrow" src="./ST Jpgs/arrow.png" alt="" />
                </div>
                {/* <img className='signature-img' src='./ST Jpgs/logos.png' alt='signature' /> */}
              </div>

            </div>



            <div className="text-end ">
              {/* <div className="Hlinks ">
                        <Link to="/about-me">about me<strong>+</strong></Link>
                        <Link to="/book">book<strong>+</strong></Link>
                        <Link to="/award">awards<strong>+</strong></Link>
                        <Link to="/contact">contact<strong>+</strong></Link>
                    </div> */}
              <div className="Hlinks film-m-menu">
                <Link to="/about-me">about me<strong>+</strong></Link>
                <Link to="/book">book<strong>+</strong></Link>
                <Link to="/award">awards<strong>+</strong></Link>
                <Link to="/contact">contact<strong>+</strong></Link>
              </div>


            </div>


          </div>
        </div>
        {/* HEADER END */}
        <div className="m-main  " >
          <div className="DesignDigital-page">
            <div className="side-text">
              <div class="DesignDigital-section">
                <div class="DesignDigital-description" style={{ fontFamily: 'Extrabold' }}>
                  <h2>

                    Love crafting <br />
                    brands, creating <br />
                    designs, & telling<br />
                    stories through<br />
                    shapes.<br />
                    AI & Digital are<br />
                    all around us,<br />
                    & this medium<br />
                    fascinates us.<br />
                  </h2>
                  <div class="DesignDigital-image">
                    <img
                      src="./characters/DesignDigital.png"
                      alt="designdigital Avatar"
                    />
                  </div>
                </div>
                {/* <div class="DesignDigital-image">
                  <img
                    src="./assets/designdigitaljpg.jpg"
                    alt="designdigital Avatar"
                  />
                </div> */}
              </div>
            </div>
            <div
              className="grid-container-DesignDigital"

            >
              {Array.from({ length: totalImages }).map((_, index) => (
                <div
                  key={index}
                  className="grid-item-DesignDigital"
                  style={{ marginTop: "1rem" }}
                >
                  <img
                    src={`./Dpages/d${index + 1}.jpg`}
                    alt={`Design&Digital ${index + 1}`}
                    className="award-image"
                  />
                  <span className="label-DesignDigital">
                    click here to expand
                  </span>
                  <button
                    className="add-button"
                    onClick={() => handleModalShow(index)}
                  >
                    +
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="footer footer-film"
        >
          {/* <div className="ft-film-text">
            <div
              className="filmfotdivs"
            >
              <div className="text-1">

                After graduating in filmmaking, I explored the artistic side of advertising, guided by my mentor Sachin Chitale at TBWA/RAAD. He helped me develop my skills in art direction, compositions, and
              </div>
              <div className="text-2">
                illustrations. This started my career in Art Direction. I later worked with renowned art titans Ryan Akitson and Kapil Bhimkar, significantly enhancing my skills in art direction and design.
              </div>
            </div>
          </div> */}


        </div>
      </div>

      <div>
      </div>
      <Modal
        className="film-modal ds design-modal"
        show={showModal}
        onHide={handleModalClose}
        dialogClassName="gray-modal"
        aria-labelledby="custom-modal-styling-title"
        centered
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="Awardcontainer">
            <Modal.Header>
              <div className="row  w-100">
                <div className="col-5 align-self-center">
                  <Modal.Title className="modal-title" id="custom-modal-styling-title">
                    <h2>design & digital</h2>
                  </Modal.Title>
                </div>

                <div className="col">
                  <Button
                    variant="light"
                    className="close-button-modal"
                    onClick={handleModalClose}
                  >
                    Close
                  </Button>
                </div>

              </div>


            </Modal.Header>
            <Modal.Body className="modal-body">
              <img
                src={
                  isTabletOrMobile
                    ? `./Dpages/Desktop/d${modalImageIndex + 1}.jpg`
                    : `./Dpages/Mobile/md${modalImageIndex + 1}.jpg`
                }
                alt={`Design & Digital ${modalImageIndex + 1}`}
                className="modal-image"
              />
            </Modal.Body>
            <Modal.Footer>
              <div className="Awardfooter">
                <div className="Awardmaindiv align-items-center">
                  <div className="agency my-0 d-none d-lg-block">
                    {modalImageIndex === 1 && (
                      <>
                        <div className="ft-d-wrap">
                          <span

                            style={{
                              color: "White",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            WCAG AA Certified
                          </span>
                          <p className="mb-0" style={{ color: "white" }}>Branding and Websites</p>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="buttons  justify-content-center justify-content-lg-end">
                    {modalImageIndex > 0 && (
                      <div>
                        <img
                          src="./assets/Arrows-prev.png"
                          alt="left-arrow"
                          onClick={handlePrevImage}
                        />
                      </div>
                    )}
                    {modalImageIndex < 2 && (
                      <div className="ms-lg-auto">
                        <img
                          src="./assets/Arrows-next.png"
                          alt="right-arrow"
                          onClick={handleNextImage}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
}
