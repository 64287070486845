import "./FilmsPage.css";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Dropdown } from "react-bootstrap";
import ReactPlayer from 'react-player';

export default function FilmsPage() {
  const isTabletOrMobile = useMediaQuery({ minWidth: 991 });

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
    setShowVideo(false);
  }
  const [modalImageIndex, setModalImageIndex] = useState(0);
  const totalImages = 6;

  const handleModalShow = (index) => {
    setModalImageIndex(index);
    setShowModal(true);
  };
  const handlePrevImage = () => {
    if (modalImageIndex > 0) {
      setShowVideo(false)
      setModalImageIndex(modalImageIndex - 1);
    } else {
      setModalImageIndex(totalImages - 1);
    }
  };

  const handleNextImage = () => {
    if (modalImageIndex < totalImages - 1) {
      setShowVideo(false);
      setModalImageIndex(modalImageIndex + 1);
    } else {
      setModalImageIndex(0);
    }
  };
  const [showVideo, setShowVideo] = useState(false);

  const handleImageClick = () => {
    setShowVideo(true);
  };
  return (
    <>
      <div
        className="start-film"

      >

        {/* HEADER */}
        <div className='header d-flex align-items-center'>
          <div className='header-item flex-wrap d-flex w-100  align-items-center justify-content-between ' >
            <div className="d-flex flex-column flex-sm-row flex-wrap align-sm-items-center">
              <div className="d-flex align-items-center">
                <Dropdown className="custom-dropdown">
                  <Dropdown.Toggle id="dropdown-custom">
                    <FaBars />
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ backgroundColor: 'black' }}>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/about-me">about Me+</Dropdown.Item>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/book">book+</Dropdown.Item>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/award">awards+</Dropdown.Item>
                    <Dropdown.Item className='dropdown-items' as={Link} to="/contact">contact+</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Link to="/" >
                  <img class="logo" src='./assets/logo.png' />
                  {/* <h1 className='logo-text'>the<strong>shuj</strong></h1> */}
                </Link>
              </div>

              <div className="text-end   ">

                <div
                  className="navlinks"
                  style={{
                    marginLeft: !isTabletOrMobile && "2.5rem",
                    fontSize: !isTabletOrMobile && "10px",
                  }}
                >
                  <Link to="/film" className="active">film<strong>+</strong>
                  </Link>
                  <Link to="/compaign">campaign<strong>+</strong></Link>
                  <Link to="/digital-and-design">digitall &amp; design<strong>+</strong></Link>

                  <img className="arrow" src="./ST Jpgs/arrow.png" alt="" />
                </div>
                {/* <img className='signature-img' src='./ST Jpgs/logos.png' alt='signature' /> */}
              </div>

            </div>



            <div className="text-end ">
              {/* <div className="Hlinks ">
                        <Link to="/about-me">about me<strong>+</strong></Link>
                        <Link to="/book">book<strong>+</strong></Link>
                        <Link to="/award">awards<strong>+</strong></Link>
                        <Link to="/contact">contact<strong>+</strong></Link>
                    </div> */}
              <div className="Hlinks film-m-menu">
                <Link to="/about-me">about me<strong>+</strong></Link>
                <Link to="/book">book<strong>+</strong></Link>
                <Link to="/award">awards<strong>+</strong></Link>
                <Link to="/contact">contact<strong>+</strong></Link>
              </div>


            </div>


          </div>
        </div>
        {/* HEADER END */}


        <div className="m-main" >
          <div className="films-page">
            <div className="text-side" >
              <div class="content-films">
                <h6>
                  I have included a selection of recent films that showcase my
                  art direction skills, enhancing the cinematographic richness.
                  These works emphasize the artful use of lighting, color
                  grading, and composition in our cinematic expression.
                </h6>
              </div>
              <div class="films-section">
                <div class="films-description">
                  <h5>
                    Films <br /> has <br />  always <br /> been<br />
                    my passion<br />  and forte.
                  </h5>
                </div>
                <div class="films-image">
                  <img src="./characters/Film.png" alt="films Avatar" />
                </div>

              </div>
            </div>
            <div
              className="grid-container-films"

            >
              {Array.from({ length: totalImages }).map((_, index) => (
                <div
                  key={index}
                  className="grid-item-films"

                >
                  <img
                    src={`./FilmsPopups/f${index + 1}.jpg`}
                    alt={`Films ${index + 1}`}
                    className="award-image"
                  />
                  <span className="label">click here to expand</span>
                  <button
                    className="add-button"
                    onClick={() => handleModalShow(index)}
                  >
                    +
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>


        <div
          className="footer footer-film"
        >
          <div className="ft-film-text">
            <div
              className="filmfotdivs"
            >
              <div className="text-1">

                I have been fortunate to collaborate with Imersely talented
                createive gurus such as Kalpesh Pitanker, Driyan Kripllan Farkhan
                Siddique , and the amazing Aanlndo Sen, a friend and a great leader.
              </div>
              <div className="text-2">
                the amazing Aanlndo Sen, a friend and a great leader. Their
                acceptional visionary approaches have heard my craft and allowed me
                to collaborate to Projects
              </div>
            </div>
          </div>


        </div>

      </div>

      <Modal
        show={showModal}
        onHide={handleModalClose}
        dialogClassName="gray-modal"
        aria-labelledby="custom-modal-styling-title"
        centered
        className="film-modal fm"
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="Awardcontainer">
            <Modal.Header className="modal-header pe-0">
              <div className="row  w-100">
                <div className="col-5 align-self-center ">
                  <Modal.Title className="modal-title" id="custom-modal-styling-title">
                    <h2>Films</h2>
                  </Modal.Title>
                </div>

                <div className="col-lg-7 ps-0 d-none d-lg-block">
                  {modalImageIndex === 0 && (
                    <div
                      className="agency"

                    >
                      <div className="d-flex">
                        <div
                          className="top-header-text pe-3"
                          style={{ borderRight: "1px solid white" }}
                        >
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Agency
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Leo Burnett-Dubai
                            </small>
                          </p>
                        </div>
                        <div className="top-header-text ps-3">
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Production House
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Protigious-Dubai{" "}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalImageIndex === 1 && (
                    <div
                      className="agency"

                    >
                      <div className="d-flex">
                        <div
                          className="top-header-text pe-3"
                          style={{ borderRight: "1px solid white" }}
                        >
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Agency
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Leo Burnett-Dubai
                            </small>
                          </p>
                        </div>
                        <div className="top-header-text ps-3">
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Production House
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Zombie-Brazil{" "}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalImageIndex === 2 && (
                    <div
                      className="agency"

                    >
                      <div className="d-flex">
                        <div
                          className="top-header-text pe-3"
                          style={{ borderRight: "1px solid white" }}
                        >
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Agency
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Leo Burnett-Dubai
                            </small>
                          </p>
                        </div>
                        <div className="top-header-text ps-3">
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Production House
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Goldmine{" "}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalImageIndex === 3 && (
                    <div
                      className="agency"

                    >
                      <div className="d-flex">
                        <div
                          className="top-header-text pe-3"
                          style={{ borderRight: "1px solid white" }}
                        >
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Agency
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Leo Burnett-Dubai
                            </small>
                          </p>
                        </div>
                        <div className="top-header-text ps-3">
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Production House
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Goldmine{" "}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalImageIndex === 4 && (
                    <div
                      className="agency"

                    >
                      <div className="d-flex">
                        <div
                          className="top-header-text pe-3"
                          style={{ borderRight: "1px solid white" }}
                        >
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Agency
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Leo Burnett-Dubai
                            </small>
                          </p>
                        </div>
                        <div className="top-header-text ps-3">
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Production House
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Bikahuna-Dubai{" "}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {modalImageIndex === 5 && (
                    <div
                      className="agency"

                    >
                      <div className="d-flex">
                        <div
                          className="top-header-text pe-3"
                          style={{ borderRight: "1px solid white" }}
                        >
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Agency
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Leo Burnett-Dubai
                            </small>
                          </p>
                        </div>
                        <div className="top-header-text ps-3">
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            Production House
                          </span>
                          <p>
                            {" "}
                            <small style={{ color: "white", fontSize: "x-small" }}>
                              Dejavu-Dubai{" "}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <Button
                    variant="light"
                    className="close-button-modal"
                    onClick={handleModalClose}
                  >
                    Close
                  </Button>
                </div>
                <div className="col-lg-7 ps-0 d-block d-lg-none">
                  <Button
                    variant="light"
                    className="close-button-modal"
                    onClick={handleModalClose}
                  >
                    Close
                  </Button>
                </div>
              </div>


            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="row  w-100 d-flex flex-column flex-md-row" style={{ "transform": "translateX(11px)" }}>
                <>
                  <div className={`col-lg-5 modal-text  order-lg-2 order-1 `}
                  // style={{
                  //   borderRight: "2px solid white",
                  //   paddingTop: "2rem",
                  //   paddingLeft: "4rem",
                  //   marginBottom: !isTabletOrMobile && "1.5rem",
                  // }}
                  >
                    {modalImageIndex === 0 && (
                      <div style={{ lineHeight: "1em" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            lineHeight: "1em",
                          }}
                        >
                          <h2>Emirates NBD <br />Private Banking</h2>
                        </div>
                        <div style={{ marginTop: "8px" }}>
                          <h5
                          >
                            Creative Expression
                          </h5>
                          <p
                            className="col-sm-8"
                          >
                            Every person's next is different. It differs in
                            scale, ambition, intent, reason and more. But it
                            is the nexts that go beyond just the personal
                            realm that actually change lives, spark progress
                            and leave a legacy.
                          </p>
                        </div>
                        <div

                        >
                          <h3 style={{ color: "red", fontWeight: "bold" }}>
                            Believe in <br /> Your next
                          </h3>
                        </div>
                      </div>
                    )}
                    {modalImageIndex === 1 && (
                      <div style={{ lineHeight: "1em" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            lineHeight: "1em",
                          }}
                        >
                          <h2>Emirates NBD <br /> Financial Wellness </h2>
                        </div>
                        <div style={{ marginTop: "8px" }}>
                          <h5

                          >
                            Creative Expression
                          </h5>
                          <div
                            className="agency"
                            style={{
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <div
                              className="row"

                            >
                              <p
                                className="col-6 mb-2"

                              >
                                This is animated story of a couple in pursuit
                                of happiness and security for their family in
                                the form of a healthy bank balance.
                              </p>
                              <p
                                className="col-6 mb-2"

                              >
                                And so, in the pursuit of the perfect life
                                balance, the couple learns and adapts to any
                                new chapter of their life. And their financial
                                knowledge evolves with them.
                              </p>
                            </div>
                            <div
                              className="row"
                              style={{
                                margin: 0,
                                padding: 0,
                                color: "white",
                                lineHeight: "1em",
                              }}
                            >
                              <p
                                className="col-6"
                                style={{ margin: 0, padding: 0 }}
                              >
                                But phase in their life comes with its own
                                needs and challenges.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div

                        >
                          <h3 style={{ color: "red", fontWeight: "bold" }}>
                            The Pursuit of Balance
                          </h3>
                        </div>
                      </div>
                    )}
                    {modalImageIndex === 2 && (
                      <div style={{ lineHeight: "1em" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            lineHeight: "1em",
                          }}
                        >
                          <h2>Home Centre <br />Ramadan 2024</h2>
                          <h6 style={{ color: "red", fontWeight: "bold" }}>
                            Make More Room For Ramadan
                          </h6>
                        </div>
                        <div style={{ marginTop: "8px" }}>
                          <div
                            className=""
                            style={{
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <div
                              className="row"
                              style={{
                                margin: 0,
                                padding: 0,
                                color: "white",
                                fontSize: "10px",
                                lineHeight: "1em",
                              }}
                            >
                              <p
                                className="col-5"
                                style={{ margin: 0, padding: 0 }}
                              >
                                We want to make more room for people, more
                                people for joy, and all the mishaps that come
                                with it. We want to capture the joy of these
                              </p>
                              <p
                                className="col-6"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginLeft: "10px",
                                }}
                              >
                                <div>little mishaps that are refreshing</div>
                                <div>little unscripted moments in life.</div>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {modalImageIndex === 3 && (
                      <div style={{ lineHeight: "1em" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            lineHeight: "1em",
                          }}
                        >
                          <h2>Home Centre <br /> Modular Film</h2>
                        </div>
                        <div

                        >
                          <div
                            className=""
                            style={{
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <div
                              className="row"
                              style={{
                                margin: 0,
                                padding: 0,
                                color: "white",
                                fontSize: "10px",
                                lineHeight: "1em",
                              }}
                            >
                              <p
                                className="col-5"
                                style={{ margin: 0, padding: 0 }}
                              >
                                It's great when things fall into place. And
                                that's exactly what happens when you have
                                modular furniture that is customized and
                              </p>
                              <p
                                className="col-5"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginLeft: "10px",
                                }}
                              >
                                tailored to fit your home. When your furniture
                                maximizes the space you have, everything finds
                                its own place.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            lineHeight: "1em",
                          }}
                        >
                          <h3 className="mt-2" style={{ fontWeight: "bold", color: "red" }}>
                            Made to
                            <br />
                            Fit your Life
                          </h3>
                        </div>
                      </div>
                    )}
                    {modalImageIndex === 4 && (
                      <div style={{ lineHeight: "1em" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            lineHeight: "1em",
                          }}
                        >
                          <h2>Nutella <br /> Biscuits</h2>
                        </div>
                      </div>
                    )}
                    {modalImageIndex === 5 && (
                      <div style={{ lineHeight: "1em" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "32px",
                            lineHeight: "1em",
                          }}
                        >
                          <h2>Emirates NBD  Ramadan</h2>
                        </div>
                        <div
                          style={{ marginTop: "10px", marginBottom: "12px" }}
                        >
                          <div
                            className=""
                            style={{
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <div
                              className="row"
                              style={{
                                margin: 0,
                                padding: 0,
                                color: "white",
                                fontSize: "10px",
                                lineHeight: "1em",
                              }}
                            >
                              <p
                                className="col-5"
                                style={{ margin: 0, padding: 0 }}
                              >
                                Ramadan is a time that brings us closer. It's
                                time spent with loved ones. It's time for
                                togetherness. A time to bond with family and
                                friends we might not see as often as we
                                should.
                              </p>
                              <p
                                className="col-5"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  marginLeft: "10px",
                                }}
                              >
                                <div>
                                  But what about the one man in the world who
                                  will be alone this Ramadan?
                                </div>
                                <div>No family. No friends. No one.</div>
                                <div
                                  style={{
                                    marginTop: "1rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Any guess who that could be?
                                </div>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row"

                        >
                          <span className="col-8">
                            This Ramadan, Emirates NBD pays tribute to the one
                            man who has taken this great nation to new
                            heights.
                          </span>
                        </div>
                        <div
                          className="row"

                        >
                          <span className="col-8">
                            And despite it being the holy month of Ramadan, he
                            has sacrificed being closer to family, to take the
                            UAE closer to new frontiers.
                          </span>
                        </div>
                        <div

                        >
                          <h3 style={{ fontWeight: "bold", color: "red" }}>
                            Sultan Al Neyadi.
                          </h3>
                        </div>
                        <div
                          className="row"
                          style={{
                            marginBottom: "10px",
                            fontSize: "13px",
                            lineHeight: "1em",
                          }}
                        >
                          <div className="col-8">
                            This month, we get the whole nation to wish him{" "}
                            <b>Ramadan Kareem!</b>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`col-lg-7 order-0 order-lg-2 modal-right align-self-center`}

                  >
                    <div
                    // style={{
                    //   marginTop: modalImageIndex === 5 ? "22px" : "",
                    // }}
                    >
                      {showVideo ? (
                        <ReactPlayer
                          url={`./FilmsPopups/videos/f${modalImageIndex + 1}.mp4`}
                          className='modal-video'
                          controls
                          width='100%'
                          height='100%'
                          playing
                          onEnded={() => setShowVideo(false)}
                        />
                      ) : (
                        <img
                          src={`./FilmsPopups/Desktop/f${modalImageIndex + 1}.jpg`}
                          alt={`Desktop Films ${modalImageIndex + 1}`}
                          className="modal-image"
                          onClick={handleImageClick}
                        />
                      )}
                    </div>
                  </div>
                </>
                {/* )} */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="Awardfooter ">
                <div className="Awardmaindiv  p-0">
                  {modalImageIndex === 0 && (
                    <>

                      <div className="row w-100 d-none d-lg-flex ">
                        <div className="col-5"></div>
                        <div className="col col-offset-5 d-flex justify-content-between ms-auto">
                          <div
                            className="agency ms-2 ps-1 my-0 "
                          >
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Team
                            </span>
                            <div
                              style={{
                                color: "white",
                                fontSize: "10px",
                                lineHeight: "1em",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Creative Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Diviyan Kripilani{" "}
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Associate Creative Director (Copy)
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Farhan Saddique
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Senior Art Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Syed Shuja
                                </div>
                              </div>
                            </div>
                          </div>



                        </div>
                      </div>


                    </>
                  )}
                  {modalImageIndex === 1 && (
                    <>
                      <div className="row w-100 d-none d-lg-flex">
                        <div className="col-5 ">
                          <div className="row">
                            <div className="col-6">
                              <div className="agency">
                                <div className="row">
                                  <div
                                    className="col-10"
                                    style={{
                                      color: "white",
                                      fontSize: "10px",
                                      lineHeight: "1em",
                                      marginTop: "-0.5rem",
                                    }}
                                  >
                                    {" "}
                                    What my start as needed to their spending will
                                    become a need for savings, followed by borrowing and
                                    ultimately investing, representing the climax of
                                    financial mindfullness, clossing the circle of their
                                    mindfullness.
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="agency">
                                <div className="row">
                                  <div
                                    className="col-10"
                                    style={{
                                      color: "white",
                                      fontSize: "10px",
                                      lineHeight: "1em",
                                      marginTop: "-0.5rem",
                                    }}
                                  >
                                    {" "}
                                    And when later in life they will look back and their
                                    realize how it was mastering these four pilars set
                                    the right foundation to turn their financial
                                    situation into a safe harbor where there family can
                                    thrive.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                        <div className="col-lg-7 ">
                          <div
                            className="agency m-0 ms-2 ps-1"

                          >
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Team
                            </span>
                            <div
                              style={{
                                color: "white",
                                fontSize: "10px",
                                lineHeight: "1em",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Creative Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Diviyan Kripilani{" "}
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Associate Creative Director (Copy)
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Farhan Saddique
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Senior Art Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Syed Shuja
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </>
                  )}
                  {modalImageIndex === 2 && (
                    <>

                      <div className="row w-100 d-none d-lg-flex">
                        <div className="col-5"></div>

                        <div className="col-lg-7 ">
                          <div
                            className="agency m-0 ms-2 ps-1"

                          >
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Team
                            </span>
                            <div
                              style={{
                                color: "white",
                                fontSize: "10px",
                                lineHeight: "1em",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Creative Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Diviyan Kripilani{" "}
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Associate Creative Director (Copy)
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Farhan Saddique
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Senior Art Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Syed Shuja
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>



                    </>
                  )}
                  {modalImageIndex === 3 && (
                    <>
                      <div className="row w-100 d-none d-lg-flex">
                        <div className="col-5"></div>
                        <div className="col-lg-7">
                          <div
                            className="agency m-0 ms-2 ps-1"

                          >
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Team
                            </span>
                            <div
                              style={{
                                color: "white",
                                fontSize: "10px",
                                lineHeight: "1em",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Creative Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Diviyan Kripilani{" "}
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Associate Creative Director (Copy)
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Farhan Saddique
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Senior Art Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Syed Shuja
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </>
                  )}
                  {modalImageIndex === 4 && (
                    <>

                      <div className="row w-100 d-none d-lg-flex">
                        <div className="col-5"></div>
                        <div className="col-lg-7">
                          <div
                            className="agency m-0 ms-2 ps-1"

                          >
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Team
                            </span>
                            <div
                              style={{
                                color: "white",
                                fontSize: "10px",
                                lineHeight: "1em",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Creative Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Aunindo Sen{" "}
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Senior Copy
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Paurav Shan
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Senior Art Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Syed Shuja
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </>
                  )}
                  {modalImageIndex === 5 && (
                    <>
                      <div className="row w-100 d-none d-lg-flex">
                        <div className="col-5"></div>
                        <div className="col-lg-7">
                          <div
                            className="agency m-0 ms-2 ps-1"
                            style={{
                              width: "30%",
                              marginTop: "0.5rem",
                              marginLeft: "7rem",
                            }}
                          >
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Team
                            </span>
                            <div
                              style={{
                                color: "white",
                                fontSize: "10px",
                                lineHeight: "1em",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "1em",
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Creative Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Diviyan Karipilani{" "}
                                </div>
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "x-small",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Senior Art Director
                                </div>
                                <div
                                  style={{ color: "white", fontSize: "x-small" }}
                                >
                                  Syed Shuja
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </>
                  )}

                  <div className="buttons justify-content-center justify-content-lg-end">
                    {modalImageIndex > 0 && (
                      <div >
                        <img
                          src="./assets/Arrows-prev.png"
                          alt="left-arrow"
                          onClick={handlePrevImage}
                        />
                      </div>
                    )}
                    {modalImageIndex < 5 && (
                      <div className="ms-lg-auto">
                        <img
                          src="./assets/Arrows-next.png"
                          alt="right-arrow"
                          onClick={handleNextImage}
                        />
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
}
