import React from 'react';
import { FaBars } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './Hello.css';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { BsList } from 'react-icons/bs';
import Header from '../../components/Header';

function Hello() {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 850 });
    const isTabletOrMobile = useMediaQuery({ maxWidth: 849 });

    return (
        <>
            <div className='hello-start'>
                {/* HEADER */}
                <div className='header d-flex align-items-center'>
                    <div className='header-item d-flex w-100  align-items-center justify-content-between ' >
                        <div className="d-flex align-items-center">
                            <Dropdown className="custom-dropdown">
                                <Dropdown.Toggle id="dropdown-custom">
                                    <FaBars />
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ backgroundColor: 'black' }}>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/about-me">about Me+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/book">book+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/award">awards+</Dropdown.Item>
                                    <Dropdown.Item className='dropdown-items' as={Link} to="/contact">contact+</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Link to="/" >
                                <img class="logo" src='./assets/logo.png' />
                                {/* <h1 className='logo-text'>the<strong>shuj</strong></h1> */}
                            </Link>
                        </div>


                        <div className="text-end ">
                            {/* <div className="Hlinks ">
                        <Link to="/about-me">about me<strong>+</strong></Link>
                        <Link to="/book">book<strong>+</strong></Link>
                        <Link to="/award">awards<strong>+</strong></Link>
                        <Link to="/contact">contact<strong>+</strong></Link>
                    </div> */}

                            <img className='signature-img' src='./ST Jpgs/logos.png' alt='signature' />
                        </div>


                    </div>
                </div>
                {/* HEADER END */}

                <div className="main-container padding-top d-flex align-items-center justify-content-between ">
                    {/* {isTabletOrMobile && (
                        <div id='for-mob'>
                            <div className='for-mobile'>
                                <h1>hello</h1>
                                <img src="./ST Jpgs/landing2.png" alt="stormtrooper" />
                            </div>
                            <div id='copyright'>
                                All reserved to <b>theshuj</b> copyright 2024
                            </div>
                        </div>
                    )} */}
                    {/* {isDesktopOrLaptop && ( */}
                    <div className="Hmain position-relative">
                        <div className="Hcontent" style={{ height: "auto" }}>
                            <h1>hello
                                <div className='img text-center' >
                                    <img src="./characters/Hello.png" alt="stormtrooper" />
                                </div>
                            </h1>

                        </div>
                        {/* <div id='copyright' className='d-block d-lg-none'>
                            All reserved to <b>theshuj</b> copyright 2024
                        </div> */}
                        <div className="Hlinks homeLink">
                            <Link to="/about-me">about me<strong>+</strong></Link>
                            <Link to="/book">book<strong>+</strong></Link>
                            <Link to="/award">awards<strong>+</strong></Link>
                            <Link to="/contact">contact<strong>+</strong></Link>
                        </div>
                    </div>
                    {/* )} */}
                </div>
                {/* <div class="hello-footer footer-mob" >
                    <div class="text-left-web d-none d-lg-block">All rights reserved to theshuj, copyright 2024</div>
                    <div class="text-right-web d-none d-lg-block">Digital Art Directed &amp; Designed by TheShuj. <br />
                    Developed, Produced and Maintained by WebStings</div><div class="ft-img d-block d-lg-none"><img class="w-100" src="./ST Jpgs/logos.png" alt="signature" /></div></div> */}
                <div className='hello-footer footer-mob' style={{ borderTop: '1px solid' }}>

                    <div className='ft-copy flex-wrap d-flex align-items-center justify-content-between   '>
                        <div className=""></div>
                        {/* <div className='text-start'>Digital Art Directed &amp; Designed by <Link to={"/"}>TheShuj</Link>. <br />
                            Developed, Produced and Maintained by <a href="https://webstings.com/" target='_blank'>WebStings</a></div> */}
                        <div class="text-end">All rights reserved to <a href='/' target='_blank'>theshuj</a> , copyright 2024 <br />Developed &amp; Supported by <a href="https://webstings.com/" target='_blank'>WebStings</a></div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default Hello;
